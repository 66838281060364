.drawerList {
	list-style: none;
	text-decoration: none;
	font-family: Inter;
	font-style: normal;
	font-weight: 500;
	font-size: 8px;
	color: #002841;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 1px 8px;
	// margin-top: 4px;
	// margin-bottom: 4px;
	cursor: pointer;
}

.title {
	margin-left: -16px;
}

.icon {
	margin-left: -3px;
}
