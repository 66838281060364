.tableHeader {
    display: flex;
    flex-wrap: wrap;
}

.tableSpan {
    padding: 0.3rem;
    width: 10px;
    border-radius: 2px;
}

.tableHeaderLeft {
    width: 50%;
    padding-left: 1rem;
}

.tableHeaderRight {
    width: 50%;
    text-align: right;
    padding-right: 2rem;
}


.button1 {
    background-color: #E0E0E0;
    height: 2rem;
    width: 9rem;
    color: #333333;
    border: none;
    border-radius: 4px;
    justify-content: center;
    margin-right: 1rem;
}

.button2 {
    background-color: #27AE60;
    height: 2rem;
    width: 9rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    justify-content: center
}

.buttonSpan {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap
}

.m1 {
    margin: 1rem 2rem 1rem 1rem;
}

.mlhalf {
    margin-left: 0.5rem;
}

.dividerClass {
    display: block;
    margin: 0;
    -webkit-flex-shrink: 0;
    -ms-flex-negative: 0;
    flex-shrink: 0;
    border-width: 0;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.12);
    border-bottom-width: 0;
    margin-top: 35px;
    height: 40px;
    border-right-width: thin;
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    align-self: stretch;
}

.header {
    font-size: 13px;
    line-height: 16px;
    color: #828282;
}

.sub {
    font-size: 13px;
    line-height: 16px;
    color: #27AE60;
    margin-top: 5px;
}

.tabs {
    display: flex;
}

.container {
    width: 100%;
    padding: 1rem 2rem;
}


.tableHeader {
    display: flex;
    flex-wrap: wrap;
}

.detailsHeader {
    margin: 1rem 1rem 0rem 1rem;
}

.back {
    display: flex;
    justify-content: left;
    align-items: center;
    flex-wrap: wrap;
    font-size: 12px;
    line-height: 14px;
    color: #4F4F4F;
}

.headerAmount {
    font-size: 21px;
    line-height: 28px;
    color: #333333;
}

.headerFlex {
    margin: 1rem;
    display: flex;
    flex-direction: row;
}


.headerFlexRight {
    display: flex;
    flex-wrap: wrap;
    justify-content: end;
}

.headerAmount {
    font-size: 24px;
    line-height: 28px;
    color: #333333;
}

.mlhalf {
    margin-left: 0.5rem;
    margin-top: 0.2rem;
}

.ml1 {
    margin-left: 1rem;
}

.ml2 {
    margin-left: 2rem;
}


.mt1 {
    margin-top: 1rem;
}

.mt2 {
    margin-top: 2rem;
}

.mb1 {
    margin-bottom: 2rem;
}

.mbn1 {
    margin-bottom: 6px;
}

.buttonSuccessful {
    width: 80px;
    height: 19px;
    background: #27AE60;
    border-radius: 2px;
    border: none;
    color: #fff;
    margin-left: 1rem;
}

.buttonAdd {
    background-color: #27AE60;
    height: 2rem;
    width: 7rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    justify-content: center
}

.detail {
    font-size: 13px;
    line-height: 16px;
    color: #333333;
    margin-top: 0.4rem;
}

.inline {
    display: flex;
    justify-content: left;
    align-items: left;
    flex-wrap: wrap;
    font-size: 13px;
    line-height: 17px;
}

.copy {
    color: #2F80ED;
    margin-top: 0.2rem;
    margin-left: 0.5rem
}

//modal

.modalContainer {
    width: 400px;
    height: auto;
    margin: auto auto;
    border-radius: 6px;
    border-color: transparent;
}

.assessmentModalContainer{
    width: 500px;
    height: auto;
    margin: auto auto;
    border-radius: 6px;
    border-color: transparent;
}

.modalColumn {
    width: 100%;
}

.modalHeader {
    font-weight: normal;
    font-size: 16px;
    line-height: 21px;
    color: #828282;
    text-align: left;
    margin: 1.5rem;
    // padding-left: 1.5rem;
}

.header {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #828282;
}

.detail {
    font-size: 13px;
    font-weight: 400;
    line-height: 16px;
    color: #333333;
    margin-top: 0.4rem;
}

.back {
    color: #333333;
}

.modalBody {
    margin: 1rem 2rem 2rem 2rem;
    color: #333333;
}

.editButton {
    background-color: #d6d6d6;
    height: 2rem;
    width: 8rem;
    color: #333333;
    border: none;
    border-radius: 4px;
    justify-content: center;
    margin-right: 1rem;
}

.downloadDiv{
    display: flex;
    justify-content: right;
}

.buttonDownload {
    background-color: #27AE60;
    height: 2.5rem;
    width: 9rem;
    color: #fff;
    border: none;
    border-radius: 4px;
    justify-content: center
}

@media (max-width: 800px) {
    .modalContainer .assessmentModalContainer {
        width: 70%;
        max-height: calc(100vh - 80px);
        overflow-y: auto;
    }

}