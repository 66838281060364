.filterModalContainer {
	border: 1px solid #d5dae1;
	box-shadow: 0px 5px 5px rgba(6, 44, 82, 0.42);
	border-radius: 3px;
	background-color: white;
	max-width: 400px;
	width: 100%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	font-family: 'Roboto', sans-serif;

	& > p {
		font-size: 0.875rem;
		color: #4f4f4f;
		margin: 1rem 1.5rem;
	}

	hr {
		background-color: #e5e9ec;
	}
}

.modalContent {
	margin: 2rem 1.5rem;
	display: grid;
	grid-gap: 1.25rem;

	input,
	select {
		background: #ffffff;
		border: 1px solid #dddddd;
		border-radius: 2px;
		width: 100%;
		padding: 0.5rem 0.6rem;
	}

	& > div:nth-child(1) {
		& > p {
			color: #333;
			font-size: 0.875rem;
		}
		& > div {
			display: flex;
			margin-top: 0.5rem;
			gap: 1rem;
			flex-wrap: wrap;

			& > p {
				border: 1px solid #dddddd;
				border-radius: 2px;
				padding: 0.375rem 0.75rem;
				color: #828282;
				font-size: 0.875rem;
			}

			& > p:hover {
				cursor: pointer;
				opacity: 0.75;
			}
		}
	}

	& > div:nth-child(2) {
		& > p {
			color: #333;
			font-size: 0.875rem;
		}
		& > div {
			display: flex;
			gap: 0.5rem;
			align-items: center;

			& > svg {
				color: #25303a;
				margin-top: 0.5rem;
			}

			& > input {
				padding-left: 0;
				padding-right: 0;
			}

			& > div {
				border: 1px solid #dddddd;
				border-radius: 2px;
				padding: 0.375rem 0.75rem;
				color: #c4c4c4;
				flex: 1;
				font-size: 0.75rem;
				margin-top: 0.5rem;
			}
		}
	}

	& > div:nth-child(3) {
		& > p {
			color: #333;
			font-size: 0.875rem;
			margin-bottom: 0.5rem;
		}
	}

	& > div:nth-child(4) {
		& > p {
			color: #333;
			font-size: 0.875rem;
			margin-bottom: 0.5rem;
		}
	}
}
