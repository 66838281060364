.form__input {
	font-size: 1.5rem;
	font-family: inherit;
	color: inherit;
	padding: 1.5rem 2rem;
	border-radius: 2px;
	background-color: rbga(#fff, 0.5);
	border: none;
	border-bottom: 3px solid transparent;
	width: 90%;
	display: block;
	transition: all 0.3s;
}

.form__label {
	font-size: 1.2rem;
	font-weight: 700;
	margin-left: 2rem;
	margin-top: 0.7rem;
	display: block;
	transition: all 0.3s;
}

.form__input:placeholder-shown + .form__label {
	opacity: 0;
	visibility: hidden;
	transform: translateY(-4rem);
}

.form__group {
	display: flex;
	width: 90%;
	flex-direction: column;
	margin: 0 auto;
	padding: 1.5rem;
}
.form__select-type {
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 19px;
	letter-spacing: 0em;
}
.form__radio-group {
	width: 100%;
	display: flex;
	align-items: center;
	border: 1px solid rgba(224, 224, 224, 1);
	margin: 10px 0;
	padding: 10px 0;
	border-radius: 4px;
}

.form__radio-input {
	display: none;
}

.form__radio-label {
	font-size: 1.6rem;
	cursor: pointer;
	position: relative;
	padding-left: 3.5rem;
}

.form__radio-button {
	height: 2rem;
	width: 2rem;
	border: 5px solid #55c57a;
	border-radius: 50%;
	display: inline-block;
	position: absolute;
	left: 1rem;
	top: 1rem;
}

.form__radio-button::after {
	content: '';
	display: block;
	height: 1rem;
	width: 1rem;
	border-radius: 50%;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: #55c57a;
	opacity: 0;
	transition: opacity 0.2s;
}

.form__radio-input:checked ~ .form__radio-label .form__radio-button::after {
	opacity: 1;
}

.content {
	padding: 0 8rem;
	margin-left: -5rem;
}

.content_h1 {
	font-size: 2rem;
	padding: 1rem 0rem;
	margin: 0;
	font-family: Roboto;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: 19px;
	letter-spacing: 0em;
}

.content_p {
	margin: 0;
	font-size: 1.4rem;
	font-family: Roboto;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 16px;
	letter-spacing: 0em;
	color: rgba(139, 139, 139, 1);
}
.active {
	border: 1px solid #27ae60;
}
.notActive {
	border: 1px solid rgba(224, 224, 224, 1);
}
