.container {
	width: 100%;
	margin: 0 auto;
}

.back {
	padding: 0 40px;
	margin: 25px 0px;
	display: flex;
	align-items: center;
	font-size: 12px;
	line-height: 14px;
	color: #4f4f4f;
	cursor: pointer;
}

.back_span {
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
}

.back_paragraph {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	color: #4f4f4f;
}

.detailsHeader {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 40px;
}

.detailsHeaderLeft {
	display: flex;
	align-items: center;
}

.detailsHeaderLeftH1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 24px;
	line-height: 28px;
	padding: 0;
	margin: 0;
	color: #333333;
}

.detailsHeaderLeftButton {
	background: #27ae60;
	border-radius: 2px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	/* identical to box height */

	text-align: center;

	color: #ffffff;
	margin: 0px 8px 8px 18px;
	padding: 3px 29px;
	outline: none;
	border: none;
}

.detailsHeaderRight {
}

.detailsHeaderRightButton {
	background: #27ae60;
	border-radius: 4px;
	padding: 7.5px 16px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.0024px;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
}

.divider_wrapper {
	margin: 29px 0;
}
.divider_wrapper_2 {
	margin: 8px 40px 22px 40px;
}

.divider_wrapper_3 {
	margin: 15px 40px 12px 40px;
}

.desc {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	padding: 0 40px;
}

.desc_content {
	display: flex;
	align-items: center;
	margin-bottom: 16px;
}

.desc_span_img {
	display: flex;
	align-items: center;
	justify-content: center;
}

.desc_paragraph {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	color: #333333;
	margin-left: 23px;
}

.businesss_trans {
	padding: 0 40px;
	margin-top: 41px;
}

.businesss_trans_h3 {
	font-family: Roboto;
	font-style: normal;
	font-weight: bold;
	font-size: 18px;
	line-height: 21px;
	/* identical to box height */

	color: #333333;
	padding: 0;
	margin: 0;
}

.gridFeatures {
	padding: 0 40px;
	margin-bottom: 25px;
}

.business_header_general {
	padding: 0 40px;
	margin-top: 60px;
}

.gridFeatureBusiness {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.gridFeatureBusinessh {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-left: 1.2619px solid #e0e0e0;
	padding-left: 40px;
}

.gridFeatureBusinessH1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 20px;
	/* or 168% */

	display: flex;
	align-items: center;

	color: #828282;
	padding: 0;
	margin: 0;
	margin-bottom: 10px;
}

.gridFeatureBusinessP {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;

	color: #333333;

	cursor: 'pointer';
}

.gridFeatureBusinessP1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;

	color: #333333;

	cursor: 'pointer';

	margin-right: 10px;
}

.gridFeatureBusinessDiv {
	display: flex;
	align-items: center;
}

.gridFeatureBusinessButton {
	margin-right: 9.5px;
	background: #27ae60;
	border-radius: 1.6px;
	padding: 2.4px 23.2px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 9.6px;
	line-height: 11px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
}

.gridFeatureBusinessPE {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
	color: #27ae60;
}
