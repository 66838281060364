.container {
	width: 100%;
	margin: 0 auto;
}

.header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	// padding: 0 40px;
	// margin-top: 30px;
}

.maintable {
	// padding: 0 40px;
	margin: 24px 0;
}

.header_left {
}

.header_left_h1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 20px;
	line-height: 23px;
	color: #333333;
}

.header_right {
	display: flex;
	align-items: center;
}

.selectwrapper {
}

.button_business {
	margin-left: 16px;
}

.button_business_button_selected {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #27ae60;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.0024px;
	color: #ffffff;
	border: none;
	outline: none;
	width: 261px;
	height: 32px;
	cursor: pointer;
	margin: 0 20px;
}

.button_business_span {
}

.tableSpan {
	padding: 3px 29px;
	border-radius: 1.5px;
	font-size: 12px;
	color: white;
}

.wrapper {
	font-size: 12px;
	box-shadow: 0px -0.65px 0px rgba(75, 112, 154, 0.18);
	border-radius: 2px 2px 8px 8px;
}

.tableVertIcon {
	cursor: pointer;
	width: 35px;
	height: 35px;
	border-radius: 50%;
	display: grid;
	place-items: center;
	transition: all 0.4s ease;
	margin-left: 10px;
}

.tableVertIcon:hover {
	background-color: rgba(255, 255, 255, 0.16);
}

.filterbutton {
	width: 84px;
	height: 31px;
	background: #e0e0e0;
	border-radius: 4px;
}

.button_business_button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #e0e0e0;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.0024px;
	color: #333333;
	border: none;
	outline: none;
	width: 131px;
	height: 32px;
	cursor: pointer;
}

.button_business_span {
	display: flex;
	justify-content: center;
	align-items: center;
}

.button_mark {
	margin-left: 20px;
}

.button_mark_button {
	display: flex;
	justify-content: center;
	align-items: center;
	background: #27ae60;
	border-radius: 4px;
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: 0.0024px;
	color: #ffffff;
	border: none;
	outline: none;
	min-width: 131px;
	height: 32px;
	cursor: pointer;
}
