.container {
	width: 100%;
	margin: 0 auto;
}

.businesss_trans {
	padding: 0 40px;
	margin-top: 41px;

	@media (max-width: 1000px) {
		padding: 0 5px;
	}
}

.businesss_trans_h3 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 14px;
	/* identical to box height */

	letter-spacing: 0.0028px;

	color: #7c7c7c;
}

.divider_wrapper_2 {
	margin: 8px 40px 22px 40px;
}

.gridFeatures {
	padding: 0 40px;
	margin-bottom: 25px;

}

.business_header_general {
	padding: 0 40px;
	margin-top: 60px;
	
}

.gridFeatureBusiness {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
}

.gridFeatureBusinessh {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	border-left: 1.2619px solid #e0e0e0;
	padding-left: 40px;
}

.gridFeatureBusinessH1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 12px;
	line-height: 20px;
	/* or 168% */

	display: flex;
	align-items: center;

	color: #828282;

	padding: 0;
	margin: 0;
	margin-bottom: 10px;
}

.gridFeatureBusinessP {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;

	color: #333333;

	cursor: 'pointer';
}

.gridFeatureBusinessP1 {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;

	color: #333333;

	cursor: 'pointer';

	margin-right: 10px;
}

.gridFeatureBusinessDiv {
	display: flex;
	align-items: center;
}

.gridFeatureBusinessButton {
	margin-right: 9.5px;
	background: #27ae60;
	border-radius: 1.6px;
	padding: 2.4px 23.2px;
	font-family: Roboto;
	font-style: normal;
	font-weight: 500;
	font-size: 9.6px;
	line-height: 11px;
	text-align: center;
	color: #ffffff;
	outline: none;
	border: none;
	cursor: pointer;
}

.gridFeatureBusinessPE {
	font-family: Roboto;
	font-style: normal;
	font-weight: normal;
	font-size: 14px;
	line-height: 16px;
	cursor: pointer;
	color: #27ae60;
}
